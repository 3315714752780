import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import PortfolioPageSections from "src/components/ProjectDetails/components/PortfolioPageSections/PortfolioPageSections"

const MotorSichPage = ({
  project: {
    pageContent: { sections },
  },
}) => {
  return (
    <div className={classes.mainCases}>
      <PortfolioPageSections sections={sections} />
    </div>
  )
}

export default MotorSichPage
