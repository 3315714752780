import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"
import PortfolioPageSections from "src/components/ProjectDetails/components/PortfolioPageSections/PortfolioPageSections"

const PinTheLookPage = ({
  project: {
    pageContent: { sections, challenges },
  },
}) => {
  return (
    <div className={classes.mainCases}>
      <PortfolioPageSections sections={sections} />
      <section className={classes.section}>
        <Challenges jsonData={challenges} />
      </section>
    </div>
  )
}

export default PinTheLookPage
