import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Image from "../image"
import CommonTextBlock from "../ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"

const CarSharingPage = ({ project }) => {
  return (
    <div className={`${classes.mainCases}`}>
      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Registration</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.registration.mainText}
            />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Find a Ride</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.findRide.mainText} />
          </div>
        </div>
        <Image src={project.pageContent.findRide.img[0].src} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Create a Ride Alert</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.rideAlert.mainText}
            />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Book a Ride</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.bookRide.text} />
          </div>
        </div>
        <div className={`${classes.imagesWrapper} ${classes.imageRide}`}>
          <Image
            src={project.pageContent.bookRide.img[0].src}
            className={`${classes.imageLeft}`}
          />
          <Image
            src={project.pageContent.bookRide.img[1].src}
            className={`${classes.imageRight}`}
          />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Registration User</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.registrationUser.text}
            />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Add a Car for Rides</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.carForRiders.text} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Create a Ride</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.createRide.text} />
          </div>
        </div>
        <Image src={project.pageContent.createRide.img[0].src} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>
              Admin Dashboard and it's Preferences
            </h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.adminBoard.text} />
          </div>
        </div>
        <Image src={project.pageContent.adminBoard.img[0].src} />
      </section>

      <section className={classes.section}>
        <Challenges jsonData={project.pageContent.challenges} />
      </section>
    </div>
  )
}

export default CarSharingPage
