import React from "react"
import classes from "./IntroPhoto.module.scss"
import Image from "components/image"

const IntroPhoto = ({ photo }) => {
  return (
    <Image
      src={photo.url}
      className={`${classes.borderedImg} ${
        photo.bordered && classes.imgWithBorder
      }`}
    />
  )
}

export default IntroPhoto
