import React from "react"
import classes from "./TextBlockWithTitle.module.scss"

const TextBlockWithTitle = ({ jsonData }) => {
  return (
    <div className={classes.textBlockWithTitle}>
      {jsonData.map(item => (
        <div className={`${classes.textWrapper}`} key={item.id}>
          {item.name && <h3 className={classes.subtitle}>{item.name}</h3>}
          <p className={classes.text}>{item.text}</p>
        </div>
      ))}
    </div>
  )
}

export default TextBlockWithTitle
