import React from "react"
import classes from "src/components/ProjectDetails/ProjectDetails.module.scss"
import MediaQuery from "react-responsive"
import CommonTextBlock from "src/components/ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import Image from "src/components/image"

const PortfolioPageSections = ({ sections }) => {
  return sections.map((section, index) => (
    <section className={classes.section} key={index}>
      <div className={classes.textContentWrapper}>
        <div className={classes.leftColumn}>
          <MediaQuery maxWidth={1024}>
            <h3 className={classes.title}>{section.title}</h3>
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <h3 className={classes.title}>
              {section.title.split("\n").map(title => (
                <p>{title}</p>
              ))}
            </h3>
          </MediaQuery>
        </div>
        <div className={classes.rightColumn}>
          <CommonTextBlock jsonData={section.text} />
        </div>
      </div>
      <Image src={section.image} />
    </section>
  ))
}

export default PortfolioPageSections
