import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Image from "../image"
import TextBlockWithTitle from "../ProjectDetails/components/TextBlockWithTitle/TextBlockWithTitle"
import CommonTextBlock from "../ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import ImageWithBotDecore from "../ProjectDetails/components/ImageWithBotDecore/ImageWithBotDecore"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"

const TamilCulturePage = ({ project }) => {
  return (
    <div className={`${classes.mainCases}`}>
      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>User Roles</h3>
          </div>
          <div className={classes.rightColumn}>
            <TextBlockWithTitle
              jsonData={project.pageContent.userRoles.roles}
            />
          </div>
        </div>
        <Image
          src={project.pageContent.userRoles.img.mainImg}
          className={classes.imageBordered}
        />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Registration</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.registration.mainText}
            />
          </div>
        </div>

        <div
          className={`${classes.imagesWrapper} ${classes["imagesWrapper__threeCol"]}`}
        >
          <div className={classes.left}>
            <Image
              src={project.pageContent.registration.img[0].src}
              className={`${classes.image}`}
            />
          </div>

          <div className={classes.right}>
            <Image
              src={project.pageContent.registration.img[1].src}
              className={`${classes.image}`}
            />
            <Image
              src={project.pageContent.registration.img[2].src}
              className={`${classes.image}`}
            />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Publishing</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.publishing} />
          </div>
        </div>
        <Image src="tamil/screen-5.png" className={classes.imageBordered} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Networking</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.networking} />
          </div>
        </div>
        <Image src="tamil/screen-6.png" className={classes.imageBordered} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Notifications</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.notifications} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Market</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.market} />
          </div>
        </div>
        <ImageWithBotDecore jsonData={project.pageContent.imageWithBotDecore} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>User’s Settings</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.userSettings} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>User’s Dashboard</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.userDashboard} />
          </div>
        </div>
        <Image src="tamil/screen-9.png" className={classes.imageBordered} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Admin Dashboard</h3>
          </div>

          <div className={classes.rightColumn}>
            <TextBlockWithTitle jsonData={project.pageContent.adminDashboard} />
          </div>
        </div>

        <div className={classes.wrapperTwoImage}>
          <div className={classes.leftColumn}>
            <Image src="tamil/screen-10.png" className={classes.image} />
          </div>
          <div className={classes.rightColumn}>
            <Image src="tamil/screen-11.png" className={classes.image} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <Challenges jsonData={project.pageContent.challenges} />
      </section>
    </div>
  )
}

export default TamilCulturePage
