import React from "react"
import classes from "./CasesCarousel.module.scss"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import CasesCarouselItemPrev from "./CasesCarouselItemPrev/CasesCarouselItemPrev"
import CasesCarouselItemNext from "./CasesCarouselItemNext/CasesCarouselItemNext"
import MediaQuery from "react-responsive"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

const CasesCarousel = ({ prevProject, nextProject, className }) => {
  return (
    <>
      <MediaQuery minWidth={576}>
        <div className="container">
          <div className={classes.prevNextWrapper}>
            <CasesCarouselItemPrev casePrev={prevProject} />
            <CasesCarouselItemNext caseNext={nextProject} />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={576}>
        <MultiCarousel
          setup={responsive}
          sectionTitle={"Other Cases"}
          buttonGroup={true}
          className={classes.casesCarousel}
        >
          <CasesCarouselItemPrev casePrev={prevProject} />
          <CasesCarouselItemNext caseNext={nextProject} />
        </MultiCarousel>
      </MediaQuery>
    </>
  )
}

export default CasesCarousel
