import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import BasicLayout from "components/layout/BasicLayout/BasicLayout"
import IntroCase from "./components/IntroCase/IntroCase"
import IntroPhoto from "./components/IntroPhoto/IntroPhoto"
import DecoreLines from "./components/DecoreLines/DecoreLines"
import ProjectResources from "./components/ProjectResources/ProjectResources"
import TickerRun from "../layout/TickerRun/TickerRun"
import UxrPage from "../UxrPage/UxrPage"
import TamilCulturePage from "../TamilCulturePage/TamilCulturePage"
import PredictlyPage from "../PredictlyPage/PredictlyPage"
import CarSharingPage from "../CarSharingPage/CarSharingPage"
import MyLuminettePage from "../MyLuminettePage/MyLuminettePage"
import CasesCarousel from "../Carousel/CasesCarousel/CasesCarousel"
import PinTheLookPage from "src/components/PinTheLookPage/PinTheLookPage"
import MotorSichPage from "src/components/MotorSichPage/MotorSichPage"

const ProjectDetails = ({ data: { casesJson: project } }) => {
  return (
    <>
      <BasicLayout
        stickyBtn={true}
        isOpenCV={false}
        titleStickyBtn="Estimate My Project"
      >
        <SEO title={project.title} />
        <div className="container">
          <IntroCase project={project} />

          <IntroPhoto photo={project.pageContent.introPhoto} />

          {project.name === "uxr" && <UxrPage project={project} />}

          {project.name === "tamil-culture" && (
            <TamilCulturePage project={project} />
          )}

          {project.name === "predictly" && <PredictlyPage project={project} />}

          {project.name === "my-luminette" && (
            <MyLuminettePage project={project} />
          )}

          {project.name === "car-sharing" && (
            <CarSharingPage project={project} />
          )}
          {project.name === "pin-the-look" && (
            <PinTheLookPage project={project} />
          )}
          {project.name === "motor-sich" && <MotorSichPage project={project} />}

          <DecoreLines />

          <ProjectResources jsonData={project} />
        </div>

        <TickerRun text="Get in Touch" stringTicker yellow={false} />

        <CasesCarousel
          prevProject={project.prevProject}
          nextProject={project.nextProject}
        />
      </BasicLayout>
    </>
  )
}

export const query = graphql`
  query($name: String!) {
    casesJson(name: { eq: $name }) {
      id
      name
      title
      subtitle
      preview
      prevProject {
        description
        id
        image
        title
        prevProjectUrl
      }
      nextProject {
        description
        id
        image
        title
        nextProjectUrl
      }
      pageContent {
        sections {
          title
          image
          text {
            text
            textList
          }
        }
        projName
        smallTitle
        introPhoto {
          id
          sideCircle
          url
          bordered
        }
        mainDescr {
          id
          label
          value
        }
        mainText {
          firstP
          id
        }
        media {
          id
          name
          text
        }
        registration {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
          }
        }
        admin {
          id
          text
        }
        account {
          id
          text
        }
        userRoles {
          id
          name
          roles {
            id
            name
            text
          }
          img {
            id
            imageWithBotDecore
            mainImg
            rightImg
          }
        }
        publishing {
          id
          text
        }
        networking {
          id
          text
        }
        notifications {
          id
          text
        }
        market {
          id
          text
        }
        userSettings {
          id
          text
        }
        userDashboard {
          id
          text
        }
        adminDashboard {
          id
          name
          text
        }
        addProducts {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
          }
        }
        analyzeProduct {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
          }
        }
        productComparison {
          id
          img {
            id
            src
          }
          mainText {
            text
            id
          }
        }
        modelCreation {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
            textList
          }
        }
        findRide {
          id
          img {
            id
            src
          }
          mainText {
            id
            textList
          }
        }
        bookRide {
          id
          text {
            id
            text
          }
          img {
            id
            src
          }
        }
        registrationUser {
          id
          text {
            id
            text
          }
        }
        carForRiders {
          id
          text {
            id
            text
          }
        }
        createRide {
          id
          img {
            id
            src
          }
          text {
            id
            textList
          }
        }
        adminBoard {
          id
          img {
            id
            src
          }
          text {
            id
            textList
          }
        }
        rideAlert {
          id
          mainText {
            id
            text
          }
        }
        orderFlow {
          id
          text
        }
        calculationDiscount {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
          }
        }
        translationsSystem {
          id
          text
        }
        IntegrationLogistic {
          id
          img {
            id
            src
          }
          mainText {
            id
            text
          }
        }
        imageWithBotDecore {
          id
          mainImg
          rightImg
        }
        challenges {
          id
          name
          solutionText
          task
        }
        partici {
          id
          value
        }
        timeline {
          id
          value
        }
        usedTechnologies {
          id
          text
          title
        }
      }
    }
  }
`

ProjectDetails.propTypes = {}

export default ProjectDetails
