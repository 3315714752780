import React from "react"
import classes from "../CasesCarousel.module.scss"
import Image from "../../../image"
import { Link } from "gatsby"

const CasesCarouselItemPrev = ({ casePrev }) => {
  return (
    <Link
      to={`/portfolio/${casePrev.prevProjectUrl}`}
      className={classes.prevNextCard}
    >
      <Image
        src={casePrev.image}
        className={classes.prevNextImg}
        draggable={false}
      />
      <h4 className={classes.prevNextTitle}>{casePrev.title}</h4>
      <p className={classes.prevNextDescription}>{casePrev.description}</p>
      <div className={classes.prevNextTypeApp}>Web Application</div>
    </Link>
  )
}

export default CasesCarouselItemPrev
