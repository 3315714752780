import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Image from "../image"
import CommonTextBlock from "../ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"

const MyLuminettePage = ({ project }) => {
  return (
    <div className={`${classes.mainCases}`}>
      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Order Flow</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.orderFlow} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Calculations and Discounts</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.calculationDiscount.mainText}
            />
          </div>
        </div>
        <Image src={project.pageContent.calculationDiscount.img[0].src} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Translations System</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.translationsSystem}
            />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Integration With Logistic Company</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.IntegrationLogistic.mainText}
            />
          </div>
        </div>
        <Image src={project.pageContent.IntegrationLogistic.img[0].src} />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Admin Dashboard</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.adminBoard.text} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <Challenges jsonData={project.pageContent.challenges} />
      </section>
    </div>
  )
}

export default MyLuminettePage
