import React from "react"
import classes from "./Challenges.module.scss"

const Challenges = ({ jsonData }) => {
  return (
    <>
      <h3 className={classes.title}>Challenges</h3>
      {jsonData.map(item => (
        <React.Fragment key={item.id}>
          <div className={classes.twoColumnBlock}>
            <div className={classes.leftColumn}>
              <span className={`${classes.label}`}>Challenge #{item.id}</span>
              <p className={classes.challengeSubtitle}>
                <span className={classes.challengeNumber}>#{item.id}. </span>
                {item.name}
              </p>
            </div>

            <div className={classes.rightColumn}>
              <p className={`${classes.mainText} ${classes.mb56}`}>
                {item.task}
              </p>
              {item.solutionText && (
                <>
                  <h3 className={classes.sectionSubtitle}>Solution</h3>
                  <p className={classes.mainText}>{item.solutionText}</p>
                </>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default Challenges
