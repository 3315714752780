import React from "react"
import classes from "./ProjectResources.module.scss"

const ProjectResources = ({ jsonData }) => {
  const timeline = jsonData.pageContent.timeline
  const partici = jsonData.pageContent.partici
  const usedTechnologies = jsonData.pageContent.usedTechnologies
  return (
    <div className={classes.projectResources}>
      <div className={`${classes.twoColumnBlock}`}>
        <div className={classes.leftColumn}>
          <div className={classes.descriptionItem}>
            <h4 className={classes.label}>Timeline</h4>
            {timeline.map(list => (
              <p className={classes.value} key={list.id}>
                {list.value}
              </p>
            ))}
          </div>

          <div className={classes.descriptionItem}>
            <h4 className={classes.label}>Our participation</h4>
            {partici.map(list => (
              <p className={classes.value} key={list.id}>
                {list.value}
              </p>
            ))}
          </div>
        </div>

        <div className={classes.rightColumn}>
          <h3 className={`${classes.sectionTitle}`}>Used Technologies</h3>
          {usedTechnologies.map(item => (
            <div className={classes.textWrapper} key={item.id}>
              <h4 className={classes.sectionSubtitle}>{item.title}</h4>
              <p className={classes.mainText}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProjectResources
