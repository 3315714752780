import React from "react"
import classes from "./DecoreLines.module.scss"

const DecoreLines = ({ Left }) => {
  return (
    <div
      className={`${classes.bottomDecor} ${
        Left ? classes.bottomDecorLeft : ""
      } `}
    />
  )
}

export default DecoreLines
