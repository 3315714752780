import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Image from "../image"
import ImageWithBotDecore from "../ProjectDetails/components/ImageWithBotDecore/ImageWithBotDecore"
import TextBlockWithTitle from "../ProjectDetails/components/TextBlockWithTitle/TextBlockWithTitle"
import CommonTextBlock from "../ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"

const UxrPage = ({ project }) => {
  return (
    <div className={`${classes.mainCases}`}>
      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>User Roles</h3>
          </div>

          <div className={classes.rightColumn}>
            <TextBlockWithTitle
              jsonData={project.pageContent.userRoles.roles}
            />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <ImageWithBotDecore jsonData={project.pageContent.userRoles.img} />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Registration</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.registration.mainText}
            />
          </div>
        </div>

        <div
          className={`${classes.imagesWrapper} ${classes["imagesWrapper__twoCol"]}`}
        >
          <Image
            src={project.pageContent.registration.img[0].src}
            className={`${classes.image}`}
          />
          <Image
            src={project.pageContent.registration.img[1].src}
            className={`${classes.image}`}
          />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={`${classes.title} ${classes["title__withImage"]}`}>
              Media
            </h3>
            <Image src="uxr/screen-5.png" className={classes.image} />
          </div>

          <div className={classes.rightColumn}>
            <TextBlockWithTitle jsonData={project.pageContent.media} />
          </div>
        </div>

        <div className={classes.wrapperTwoImage}>
          <div className={classes.leftColumn}>
            <Image src="uxr/screen-6.png" className={classes.image} />
          </div>
          <div className={classes.rightColumn}>
            <Image src="uxr/screen-7.png" className={classes.image} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Admin Dashboard</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.admin} />
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Account Settings</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock jsonData={project.pageContent.account} />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <Image src="uxr/screen-8.png" />
        </div>
      </section>

      <section className={classes.section}>
        <Challenges jsonData={project.pageContent.challenges} />
      </section>
    </div>
  )
}

export default UxrPage
