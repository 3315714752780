import React from "react"
import classes from "./IntroCase.module.scss"

const IntroCase = ({
  project: {
    pageContent: { mainText, mainDescr, projName },
  },
}) => {
  return (
    <div className={classes.introCase}>
      <div className={`${classes.casesHeading} position-relative`}>
        <h1 className={classes.title}>{projName}</h1>
      </div>
      <div className={classes.twoColumnBlock}>
        <div className={classes.leftColumn}>
          {mainDescr.map(list => (
            <div className={classes.descriptionItem} key={list.id}>
              <h4 className={classes.label}>{list.label}</h4>
              <p className={classes.value}>{list.value}</p>
            </div>
          ))}
        </div>
        <div className={classes.rightColumn}>
          {mainText.map(list => (
            <p className={classes.mainText} key={list.id}>
              {list.firstP}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export default IntroCase
