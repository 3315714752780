import React from "react"
import classes from "../ProjectDetails/ProjectDetails.module.scss"
import Image from "../image"
import CommonTextBlock from "../ProjectDetails/components/CommonTextBlock/CommonTextBlock"
import Challenges from "../ProjectDetails/components/Challenges/Challenges"

const PredictlyPage = ({ project }) => {
  return (
    <div className={`${classes.mainCases}`}>
      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Registration</h3>
          </div>
          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.registration.mainText}
            />
          </div>
        </div>

        <Image
          src={project.pageContent.registration.img[0].src}
          className={classes.imageBordered}
        />
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Add Products</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.addProducts.mainText}
            />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <Image
            src={project.pageContent.addProducts.img[0].src}
            className={classes.image}
          />
          <Image
            src={project.pageContent.addProducts.img[1].src}
            className={classes.image}
          />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Analyze Product</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.analyzeProduct.mainText}
            />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <Image
            src={project.pageContent.analyzeProduct.img[0].src}
            className={classes.imageBordered}
          />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Product Comparison</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.productComparison.mainText}
            />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <Image
            src={project.pageContent.productComparison.img[0].src}
            className={classes.imageBordered}
          />
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.textContentWrapper}>
          <div className={classes.leftColumn}>
            <h3 className={classes.title}>Model Creation</h3>
          </div>

          <div className={classes.rightColumn}>
            <CommonTextBlock
              jsonData={project.pageContent.modelCreation.mainText}
            />
          </div>
        </div>

        <div className={classes.imagesWrapper}>
          <Image
            src={project.pageContent.modelCreation.img[0].src}
            className={`${classes.image} ${classes.imageBordered}`}
          />
          <Image
            src={project.pageContent.modelCreation.img[1].src}
            className={`${classes.image} ${classes.imageBordered}`}
          />
        </div>
      </section>

      <section className={classes.section}>
        <Challenges jsonData={project.pageContent.challenges} />
      </section>
    </div>
  )
}

export default PredictlyPage
