import React from "react"
import Image from "../../../image"
import classes from "./ImageWithBotDecore.module.scss"
import DecoreLines from "../DecoreLines/DecoreLines"

const ImageWithBotDecore = ({ jsonData }) => {
  return (
    <div className={`${classes.oneColumnBlock}`}>
      <Image
        src={jsonData.mainImg}
        className={`${classes.imageMain} ${classes.imgWithBorder}`}
      />
      <Image
        src={jsonData.rightImg}
        className={`${classes.imageRight} ${classes.imgWithBorder}`}
        style={{ position: "absolute" }}
      />
      <DecoreLines Left />
    </div>
  )
}

export default ImageWithBotDecore
