import React from "react"
import classes from "./CommonTextBlock.module.scss"

const CommonTextBlock = ({ jsonData }) => {
  return (
    <div className={classes.commonTextBlock}>
      {jsonData.map(item =>
        item.textList ? (
          <React.Fragment key={item.id}>
            {item.text && <p className={classes.commonText}>{item.text}</p>}
            <ul className={classes.commonTextList}>
              {item.textList.map((list, index) => (
                <li className={classes.commonTextListItem} key={index}>
                  - {list}
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : (
          <p className={classes.commonText} key={item.id}>
            {item.text}
          </p>
        )
      )}
    </div>
  )
}

export default CommonTextBlock
